.sidebar{
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    z-index: 10000;
    margin-right: -300px;
    background-color: #1E3541;
    box-shadow: 0 3px 6px #00000029;
    opacity: 1;
    backdrop-filter: blur(23px);
    -webkit-backdrop-filter: blur(23px);
    min-height: 100vh;
    transition: all 0.3s;
    h1{
        background-color: #004165;
        text-transform: uppercase;
        font-size: 12px;
        padding: 12px 15px;
        text-align: center;
        color: $white;
        margin-bottom: 0;
    }
    &.active{
        margin-right: 0;
    }
    .close-icon{
        width: 19px;
        float: right;
        padding: 5px;
        cursor: pointer;
    }
    p{
        padding: 30px 30px;
        font-weight: 500;
        color: $white;
        font-size: 12px;
        margin-bottom: 0;
        text-align: center;
        letter-spacing: 1px;
        line-height: 25px;
    }
    .img-wrp{
        img{
            width: 100px;
            display: block;
            margin: 0 auto;
        }
    }
}
