/*pre-loader*/
.preloader-back{
    pointer-events: inherit;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000000;
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    &.hide {
        pointer-events: none !important;
        animation: dis-none 1s forwards;
    }
}

.vertical-center__element{
    display: block;
    margin: 0 auto;
}

.preloader {
    display: block;
    position: relative;
    width: 1em;
    height: 1em;
    margin: 1px auto;
    background: #ffcb08;

    &--top {
        top: 0;
        animation: preloaderCSSTop 2s infinite;

        &::after {
            position: absolute;
            content: "";
            display: block;
            width: 1em;
            height: 1em;
            left: 1em;
            background: #ffcb08;
            margin-left: 1px;
            animation: preloaderCSSTopAfter 2s infinite;
            animation-delay: 0.5s;
        }
    }

    &--bottom {
        top: 0;
        animation: preloaderCSSBottom 2s infinite;
        animation-delay: 1.5s;

        &::after {
            position: absolute;
            content: "";
            display: block;
            width: 1em;
            left: 1em;
            height: 1em;
            background: #ffcb08;
            margin-left: 1px;
            animation: preloaderCSSTopAfter 2s infinite;
            animation-delay: 1s;
        }
    }
}

@keyframes preloaderCSSTop {
    0% {
        background-color: #ffcb08;
    }
    100% {
        background-color: white;
    }
}

@keyframes preloaderCSSBottom {
    0% {
        background-color: #ffcb08;
    }
    100% {
        background-color: white;
    }
}

@keyframes preloaderCSSTopAfter {
    0% {
        background-color: #ffcb08;
    }
    100% {
        background-color: white;
    }
}

@keyframes preloaderCSSBottomAfter {
    0% {
        background-color: #ffcb08;
    }
    100% {
        background-color: white;
    }
}
@keyframes dis-none{
    0%{
        opacity: 1;
    }
    99%{
        opacity: 0;
    }
    100%{
        opacity: 0;
        display: none;
    }
}


/*pre-loader*/
