.footer {
    background-color: $deepBlue;
    text-align: center;
    .gov-logo {
        width: 60px;
        height: auto;
    }
    .copyrights {
        font-size: 12px;
    }
    &-bottom {
        height: 50px;
        background-color: rgba($black, 0.35);
        background-size: 63px;
        background-repeat-y: no-repeat;
        background-position-y: center;
    }
}
