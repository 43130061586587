.resorts-banner{
    height: 100vh;
    position: relative;
    margin-bottom: 50px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .resorts-banner-text{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.45);
        .banner-text-bg{
            background-color: rgba(255, 255, 255, 0.3);
            padding: 10px 0;
        }
        h1{
            color: $white;
            font-size: 50px;
            font-weight: 400;
            letter-spacing: 7px;
            text-transform: uppercase;
        }
        .para-2{
            font-size: 16px;
            font-weight: 300;
            line-height: 33px;
            margin: 25px 0 0;
            color: $white;
        }
        .scroll-down-back{
            position: absolute;
            bottom: 0;
            width: 100%;
            .scroll-down{
                margin: 0 auto 20px;
            }
        }
        .holiday-scroll{
            margin: 50px auto;
        }
    }
    .slider-navs{
        position: relative;
        .slick-arrow{
            display: inline-block;
            cursor: pointer;
            &.next{
                float: left;
                margin-left: 9%;
            }
            &.pre{
                float: right;
                margin-right: 9%;
            }
        }
        svg{
            width: 18px;
            height: 30px;
        }
        .top-slider-arrows {
            fill: none;
            stroke: #fff;
            stroke-width: 3px;
        }
    }
}
.resort-topic{
    font-size: 35px;
    letter-spacing: 2px;
    font-weight: 600;
    text-transform: uppercase;
    color: $deepBlue;
    margin-bottom: 15px;
    &:after{
        content: "";
        display: block;
        width: 11%;
        height: 3px;
        background-color: #ffcb08;
        margin-left: 5px;
    }
}
.resort-para-topic{
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 14px;
    color: $lightBrown;
}
.resort-bg-img{
    position: absolute;
    width: 25%;
}
.resort-slider-height{
    min-height: 300px;
    .resort-slider-arrows{
        display: none;
    }
}
.resort-slider{
    margin: 30px -15px 30px;
    .slick-slide{
        padding: 0 15px 40px;
        .slide-inner{
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            cursor: pointer;
            transition: .4s;
            .slider-link{
                &:hover{
                    text-decoration: none;
                    img{
                        -webkit-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                    .text p{
                        color: $yellow;
                    }
                }
                &:focus{
                    outline: 0;
                }
            }
            .img-wrap{
                overflow: hidden;
                position: relative;
                padding-top: 65%;
                height: 100%;
                width: 100%;
                img{
                    width: 100%;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                    transition: .4s;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .text{
                padding: 23px 5px;
                background-color: $white;
                p{
                    text-transform: uppercase;
                    color: $deepBlue;
                    font-size: 15px;
                    font-weight: 600;
                    margin: 0;
                    text-align: center;
                    transition: .4s;
                }
            }
            &:hover{
                box-shadow: 0 20px 20px 5px rgba(0, 0, 0, 0.1);
            }
        }
    }
}

@media (max-width: 1024px) {
    .resort-slider{
        margin: 30px -10px;
        .slick-slide{
            padding: 0 10px 40px;
            .slide-inner{
                .text{
                    padding: 23px 5px;
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .resorts-banner{
        .resorts-banner-text{
            h1{
                font-size: 28px;
                padding: 0 15px;
                letter-spacing: 5px;
            }
            .para-2{
                font-size: 14px;
                line-height: 25px;
                padding: 0 15px;
            }
        }
    }
    .resort-topic{
        font-size: 25px;
        margin-bottom: 15px;
    }
    .resort-para-topic{
        font-size: 13px;
    }
    .resort-bg-img{
        width: 43%;
    }
    .resort-slider {
        margin: 30px -10px 0;
    }
    .resort-slider-height{
        .resort-slider-arrows{
            display: block;
        }
    }
}
@media (max-width: 575px) {
    .resort-bg-img{
        width: 70%;
    }
}
