.locator-wrap {
    margin-bottom: 70px;
    .locator-slider {
        .slide-inner {
            padding: 50px 0;
            .img-wrap {
                position: relative;
                padding-top: 100%;
                height: 100%;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .slider-for-back {
        position: absolute;
        top: 0;
        left: -30%;
        width: 100%;
        height: 100%;
        align-items: center;
        .slider-for-wrap {
            padding: 25px !important;
            box-shadow: 0 3px 6px #0000003d;
            background-color: $white;
            z-index: 100;
        }
    }
    .slider-for {
        .slide-inner-text {
            .text-wrap {
                h5 {
                    font-size: 16px;
                    line-height: 28px;
                    color: $darkGray;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
                p {
                    font-size: 13px;
                    color: $darkGray;
                    font-weight: 500;
                    margin: 0;
                    line-height: 22px;
                }
                .view-btn {
                    color: $white;
                    font-size: 12px;
                    text-decoration: none;
                    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.16078);
                    border-radius: 2px;
                    background-color: $darkGray;
                    display: block;
                    padding: 8px 25px;
                    width: fit-content;
                    font-weight: 500;
                    text-transform: uppercase;
                    margin-top: 20px;
                    border: 1px solid transparent;
                    cursor: pointer;
                    transition: 0.4s;
                    outline: 0;
                    &:hover {
                        color: $darkGray;
                        background-color: $white;
                        border-color: $darkGray;
                    }
                }
            }
        }
    }
    .locator-nav {
        margin: 0 0 0 15%;
        position: relative;
        top: -10px;
        z-index: 100;
        span {
            font-size: 25px;
            line-height: 0;
            position: relative;
            top: 3px;
            color: #004165;

            img {
                cursor: pointer;
                width: 35px;
                padding: 0 2px;
                &.previous {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
@media (max-width: 991px) {
    .locator-wrap {
        margin-bottom: 50px;
        .locator-slider {
            .slide-inner {
                padding: 0;
            }
        }
        .slider-for-back {
            position: unset;
            left: 0;
            height: auto;
            align-items: unset;
            .slider-for-wrap {
                padding: 20px !important;
            }
        }
        .locator-nav {
            margin: 15px 0 0 0;
            top: 0;
            span {
                font-size: 22px;
                top: 2px;
                img {
                    cursor: pointer;
                    width: 32px;
                    padding: 0 2px;
                }
            }
        }
    }
}
