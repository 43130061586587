.home-top {
    height: 100vh;
    position: relative;
    .slick-slide {
        height: 100vh;
        & > div {
            height: 100%;
        }
        img {
            height: 100%;
            object-fit: cover;
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.45);
        &-content {
            padding: 0 15px;
            h1 {
                color: $white;
                font-size: 60px;
                font-weight: 400;
                letter-spacing: 7px;
                span {
                    color: $yellow;
                }
            }
            .para-1 {
                font-size: 15px;
                max-width: 300;
                font-weight: 300;
            }
            .para-2 {
                font-size: 18px;
                max-width: 680px;
                font-weight: 300;
                line-height: 33px;
                margin: 3rem 0;
            }
            .slick-dots {
                display: flex;
                list-style: none;
                align-items: center;
                justify-content: center;
                margin-bottom: 4rem;
                padding: 0;
                li {
                    font-size: 0;
                    width: 7px;
                    height: 7px;
                    margin: 0 4px;
                    display: block;
                    background-color: $white;
                    border-radius: 50%;
                    transition: all 0.5s ease;
                    cursor: pointer;
                    &.slick-active {
                        background-color: $yellow;
                    }
                    button {
                        display: none;
                    }
                }
            }
        }
        .slider-navs {
            position: absolute;
            bottom: 8rem;
            right: 7rem;
            svg {
                margin: 0 15px;
                cursor: pointer;
                &:hover {
                    .top-slider-arrows {
                        stroke: #ffcb08;
                    }
                }
            }
        }
    }
    .top-slider-arrows {
        fill: none;
        stroke: #fff;
        stroke-width: 3px;
    }
}

.scroll-down {
    cursor: pointer;
    width: 32px;
    height: 45px;
    border-radius: 20px;
    border: solid 1.5pt $white;
    position: relative;
    margin-bottom: 3rem;
    transition: all 0.5s ease;
    &:hover {
        background-color: rgba($white, 0.3);
        border-color: $yellow;
        & > div {
            background-color: $white;
        }
    }
    & > div {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 3pt;
        height: 15px;
        border-radius: 3px;
        background-color: $yellow;
        animation: smooth-down 1.5s ease infinite;
    }
}

.home-sec-2 {
    .image-sec {
        background-color: $lightGray;
        position: relative;
        img {
            object-fit: contain;
        }
        .text {
            position: absolute;
            text-transform: uppercase;
            top: 0;
            left: 0;

            h2 {
                font-size: 50px;
                letter-spacing: 2px;
                font-weight: 600;
                position: relative;
                &:first-child::after {
                    content: "";
                    display: block;
                    width: 40%;
                    height: 3px;
                    background-color: $yellow;
                    margin-left: 5px;
                }
            }
            p {
                letter-spacing: 1px;
                font-weight: 600;
                font-size: 22px;
            }
        }
    }
    .text-sec {
        p {
            line-height: 35px;
            font-weight: 400;
        }
    }
}

.explore-slider {
    .slick-slide {
        width: 480px;
        padding: 40px 10px;
        &.slick-current {
            .slide-inner {
                box-shadow: 0px 30px 15px 0 rgba($black, 0.1);
            }
        }
    }
    .slide-inner {
        position: relative;
        background-color: $white;
        transition: box-shadow 0.5s ease;
        box-shadow: 0px 5px 15px 0 rgba($black, 0.1);
        .img-wrap {
            padding-top: 56.25%;
            overflow: hidden;
            position: relative;
            img {
                position: absolute;
                height: 100%;
                width: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
            }
        }
        .text {
            padding: 1.5rem;
            .location {
                color: rgba($darkGray, 0.6);
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 1px;
                margin: 0 0 1.5rem;
            }
            .resort-name {
                color: $darkGray;
                font-size: 20px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin: 0;
            }
        }
    }
}

.explo-slider-arrows {
    position: relative;
    .explo-arrows {
        position: relative;
        transition: all 0.5s ease;
        stroke: none;
        fill: rgba($darkGray, 0.45);
    }
    svg {
        transform: scale(0.8);
        cursor: pointer;
        margin: 0 6px;
        &:hover {
            .explo-arrows {
                fill: $darkGray;
            }
        }
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 4px;
        margin: 0 auto;
        height: 36px;
        width: 1px;
        display: block;
        background-color: $darkGray;
    }
}

.review-sec {
    padding: 10rem 8rem 5rem 8rem !important;
    position: relative;
    .shadow-ele {
        box-shadow: 0 0 50px -15px rgba($black, 0.3);
    }
    .bg-img {
        position: absolute;
        height: 100%;
        width: auto;
        padding: 2% 0;
        right: 0;
    }
    .content-sec {
        position: relative;
        background-color: $white;
        margin: 0 -8rem;
        .text {
            color: $darkGray;
            padding: 5rem 0;
            h1 {
                font-weight: 600;
                letter-spacing: 5px;
            }
            .review {
                span:not(.quotation) {
                    max-width: 710px;
                    display: inline-block;
                    font-size: 17px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    padding: 4rem 0;
                }
                .quotation {
                    display: inline-block;
                    font-family: EngraversMT, Engravers MT;
                    font-size: 200px;
                    line-height: 0;
                    &:last-child {
                        position: relative;
                        top: 7rem;
                    }
                }
            }
            .author {
                font-weight: 700;
                font-size: 14px;
                max-width: 440px;
                letter-spacing: 1px;
            }
        }
    }
}

.video-sec {
    padding: 1rem 8rem 10rem 8rem !important;
    position: relative;
    .shadow-ele {
        box-shadow: 0 0 50px -15px rgba($black, 0.3);
    }
    .bg-img {
        position: absolute;
        height: 100%;
        width: auto;
        padding: 2% 0;
        left: 0;
        transform: scaleX(-1);
    }
    .content-sec {
        position: relative;
        background-color: $white;
        margin: 0 -8rem;
        .text {
            color: $darkGray;
            padding: 2rem 0 5rem 0;
            h1 {
                font-weight: 600;
                letter-spacing: 5px;
                margin-bottom: 20px;
            }
        }
    }
}

.location-map {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;

    .map-pin {
        cursor: pointer;
        position: absolute;
        text {
            font-weight: 600;
            font-size: 14px;
        }
        path {
            fill: $darkGray;

            transition: fill 0.5s ease;
        }
        &.active {
            path {
                fill: $yellow;
            }
        }
        &:hover {
            path {
                fill: $yellow;
            }
        }
    }
}

@keyframes smooth-down {
    0% {
        transform: translateY(5px);
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}

@media (max-width: 1024px) {
    .home-sec-2 {
        .image-sec {
            .text {
                h2 {
                    font-size: 40px;
                }
                p {
                    font-size: 20px;
                }
            }
        }
    }
    .location-map {
        left: -5%;
    }
}
@media (max-width: 992px) {
    .home-top {
        .overlay {
            &-content {
                h1 {
                    font-size: 40px;
                }
                .para-2 {
                    font-size: 15px;
                }
            }
            .slider-navs {
                position: absolute;
                bottom: 7rem;
                right: 3rem;
                svg {
                    margin: 0 10px;
                    width: 15px;
                }
            }
        }
    }
    .home-sec-2 {
        .image-sec {
            .text {
                h2 {
                    font-size: 30px;
                }
                p {
                    font-size: 18px;
                }
            }
        }
    }
    .explore-slider {
        .slick-slide {
            width: 380px;
            padding: 20px 10px;
        }
        .slide-inner {
            .text {
                padding: 1rem;
                .location {
                    margin: 0 0 1rem;
                }
                .resort-name {
                    font-size: 17px;
                }
            }
        }
    }
    .review-sec {
        padding: 5rem 1rem !important;
        .content-sec {
            margin: 0 -1rem;
            width: 100vw;
            .bg-img {
                transform: scaleX(-1);
            }
            .text {
                padding: 3rem 1rem;
                h1 {
                    font-size: 30px;
                }
                .review {
                    .quotation {
                        display: none;
                    }
                }
            }
        }
    }

    .video-sec {
        padding: 1rem 1rem 5rem 1rem !important;
        .content-sec {
            margin: 0 -1rem;
            width: 100vw;
            .bg-img {
                left: auto;
                right: 11px !important;
            }
            .text {
                padding: 3rem 1rem;
                h1 {
                    font-size: 30px;
                    max-width: 400px;
                }
                iframe {
                    transform: scale(0.6);
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .explore-slider {
        .slick-slide {
            width: 300px;
        }
    }
}
@media (max-width: 576px) {
    .location-map {
        left: -15%;
        position: relative;
        padding: 0 0 0 0;
        top: -10%;
        svg {
            width: 100%;
        }
    }
    .explore-slider {
        padding: 0 25px;
        .slick-slide {
            padding: 0 10px;
        }
    }
}
