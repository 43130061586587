.navi-style {
    background-color: rgba($white, 0.3);
    transition: background-color 0.6s ease;
    &.scrolled {
        background-color: rgba($black, 0.5);
    }
    .collapse.show {
        background-color: rgba($white, 0.3);
        margin-bottom: 35px;
    }
    .navbar-toggler {
        height: 100%;
    }
    .nav-item {
        padding: 0 15px;
        .dropdown-icon{
            position: absolute;
            top: 5px;
            right: 5px;
        }
        .dropdown-toggle::after{
            display: none;
        }
        .nav-link {
            text-transform: uppercase;
            color: $white;
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 1px;
        }
        &.active {
            .nav-link {
                color: $yellow;
                position: relative;
                &::after {
                    position: absolute;
                    content: "";
                    background-color: $yellow;
                    height: 2px;
                    width: 100%;
                    height: 2px;
                    width: calc(100% - 1rem);
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
                &::before {
                    position: absolute;
                    content: "";
                    border: solid 6px transparent;
                    height: 12px;
                    border-bottom-color: $yellow;
                    width: 12px;
                    bottom: 1px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }
        }
    }
    .navbar-brand {
        img {
            width: auto;
            height: 55px;
            object-fit: contain;
            object-position: center;
        }
    }
}

.navbar-toggler {
    background: none;
    border: none;
    &:active,
    &:focus {
        outline: 0;
    }
    .icon-bar {
        display: block;
        width: 25px;
        height: 3px;
        border-radius: 1px;
        margin: 4px 0 4px 0;
        transition: all 0.2s;
        background: $darkGray;
        .navbar-dark & {
            background: #ffffff;
        }
        &:nth-of-type(1) {
            transform: rotate(45deg);
            transform-origin: 10% 10%;
        }
        &:nth-of-type(2) {
            opacity: 0;
            filter: alpha(opacity=0);
        }
        &:nth-of-type(3) {
            transform: rotate(-45deg);
            transform-origin: 10% 90%;
        }
    }
    &.collapsed {
        .icon-bar {
            &:nth-of-type(1) {
                transform: rotate(0);
            }
            &:nth-of-type(2) {
                opacity: 1;
                filter: alpha(opacity=100);
            }
            &:nth-of-type(3) {
                transform: rotate(0);
            }
        }
    }
}
@media (max-width: 1200px) {
    .navi-style {
        background-color: #004165;
        &.scrolled{
            background-color: #004165;
        }
        .nav-item {
            padding: 10px 15px;
            width: fit-content;
            .dropdown-icon {
                top: 14px;
                right: -3px;
            }
            &.active {
                .nav-link {
                    &::after {
                        width: 100%;
                    }
                    &::before {
                        margin: 0 0 0 13px;
                    }
                }
            }
        }
        .navbar-brand {
            img {
                height: 65px;
            }
        }
    }
}
