.resort-main-slider-back{
    height: 100vh;
    position: relative;
    margin-bottom: 50px;
    .resort-main-slider{
        height: 100vh;
        .slick-list{
            height: 100%;
            .slick-track{
                height: 100%;
            }
            .slide-inner{
                height: 100vh;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
                .resorts-banner-text{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($black, 0.45);
                    .banner-text-bg{
                        background-color: rgba(255, 255, 255, 0.3);
                        padding: 10px 15px;
                    }
                    h1{
                        color: $white;
                        font-size: 50px;
                        font-weight: 400;
                        letter-spacing: 7px;
                        text-transform: uppercase;
                    }
                    .para-2{
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 33px;
                        margin: 25px 0 0;
                        color: $white;
                    }
                    .scroll-down-back{
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        .scroll-down{
                            margin: 0 auto 20px;
                        }
                    }
                    .holiday-scroll{
                        margin: 50px auto;
                    }
                }
            }
        }
    }
    .slider-navs-back{
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        .slider-navs{
            position: relative;
            width: 100%;
            .slick-arrow{
                display: inline-block;
                cursor: pointer;
                &.next{
                    float: left;
                    margin-left: 9%;
                }
                &.pre{
                    float: right;
                    margin-right: 9%;
                }
            }
            svg{
                width: 18px;
                height: 30px;
            }
            .top-slider-arrows {
                fill: none;
                stroke: #fff;
                stroke-width: 3px;
            }
        }
    }
}
.holiday-para-box-back{
    margin-bottom: 80px;
    .resort-single-name{
        font-size: 27px;
        letter-spacing: 2px;
        font-weight: 600;
        position: relative;
        text-transform: uppercase;
        margin-bottom: 17px;
    }
    .holiday-para-box{
        padding: 30px 8.33333%;
        background-color: $lightGray;
        p {
            font-weight: 500;
            font-size: 14px;
            margin: 0;
            line-height: 25px;
        }
    }
    .holiday-contact{
        padding: 0 8.3333% 0 6%;
        .holiday-contact-details{
            p{
                display: flex;
                font-weight: 500;
                font-size: 14px;
                color: $darkGray;
                margin-bottom: 18px;
                span{
                    display: block;
                }
                img{
                    width: auto;
                    margin-right: 20px;
                    height: 20px;
                }
            }
        }
    }
}
.resort-rooms-mar{
    margin: 0 -25px !important;
    .resort-rooms-back{
        padding: 0 25px !important;
        .rooms-border{
            padding: 35px 30px !important;
            /*-webkit-box-shadow: 0px -65px 47px -62px rgba(7,7,7,0.12);
            -moz-box-shadow: 0px -65px 47px -62px rgba(7,7,7,0.12);
            box-shadow: 0px -65px 47px -62px rgba(7,7,7,0.12);*/
            -webkit-box-shadow: 0px -26px 38px -22px rgba(7,7,7,0.14);
            -moz-box-shadow: 0px -26px 38px -22px rgba(7,7,7,0.14);
            box-shadow: 0px -26px 38px -22px rgba(7,7,7,0.14);
            height: 100%;
            background-color: $white;
            position: relative;
            z-index: 20;
            .rooms-img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                img{
                    width: 150px;
                    display: block;
                    margin: 0 auto;
                }
            }
            .rooms-box{
                position: relative;
                z-index: 20;
                h1{
                    font-size: 28px;
                    color: $darkGray;
                    font-weight: 500;
                    margin-bottom: 30px;
                }
                h2{
                    font-size: 14px;
                    color: $darkGray;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
                h3{
                    font-size: 13px;
                    color: $darkGray;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
                p{
                    font-size: 13px;
                    color: $darkGray;
                    font-weight: 500;
                    margin-bottom: 0;
                    span{
                        font-weight: 600;
                    }
                }
                ul{
                    padding: 0 0 0 15px;
                    margin: 0;
                    li{
                        font-size: 13px;
                        color: $darkGray;
                        font-weight: 500;
                        margin-bottom: 10px;
                        span{
                            font-weight: 600;
                        }
                    }
                }
                .booking-btn{
                    color: $white;
                    font-size: 12px;
                    text-decoration: none;
                    box-shadow: 0 6px 8px rgba(0,0,0,0.16078);
                    border-radius: 2px;
                    background-color: $darkGray;
                    display: block;
                    padding: 7px 15px;
                    width: fit-content;
                    font-weight: 400;
                    text-transform: uppercase;
                    margin-top: 40px;
                    border: 1px solid transparent;
                    cursor: pointer;
                    transition: .4s;
                    &:hover{
                        color: $darkGray;
                        background-color: $white;
                        border-color: $darkGray;
                    }
                }
            }
        }
    }
}
.resort-activities{
    margin: 8% 0 50px;
    position: relative;
    .resort-activities-img{
        position: absolute;
        top: -15%;
        width: 30vw;
    }
}
.center-slider-back{
    position: absolute;
    bottom: 4%;
    width: 100%;
}
.center-slider{
    margin-bottom: 2%;
    .slick-list{
        padding: 0!important;
        .slick-slide{
            .slide-inner{
                .img-wrap{
                    position: relative;
                    padding-top: 100%;
                    height: 100%;
                    width: 100%;
                    img{
                        width: 100%;
                        -webkit-transform: scale(0.9);
                        transform: scale(0.9);
                        border: 4px solid transparent;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
            &.slick-current{
                .slide-inner{
                    img{
                        -webkit-transform: scale(1);
                        transform: scale(1);
                        border-color: $white;
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .resort-main-slider-back{
        .resort-main-slider{
            .slick-list{
                .slick-track{
                }
                .slide-inner{
                    .resorts-banner-text{
                        h1{
                            font-size: 42px;
                        }
                    }
                }
            }
        }
    }
    .holiday-para-box-back{
        .resort-single-name{
            font-size: 25px;
        }
    }
    .center-slider-back{
        bottom: 6%;
    }
}
@media (max-width: 991px) {
    .holiday-para-box-back{
        margin-bottom: 50px;
        .resort-single-name{
            font-size: 20px;
        }
    }
    .resort-rooms-mar{
        .resort-rooms-back{
            padding: 25px !important;
            .rooms-border{
                padding: 25px 20px !important;
                box-shadow: 0 3px 10px 4px rgba(0, 0, 0, 0.16078);
            }
        }
    }
}
@media (max-width: 768px) {
    .resort-main-slider-back{
        .resort-main-slider{
            .slick-list{
                .slick-track{
                }
                .slide-inner{
                    .resorts-banner-text{
                        h1{
                            font-size: 25px;
                            letter-spacing: 5px;
                        }
                        .para-2{
                            font-size: 14px;
                            line-height: 25px;
                            padding: 0 15px;
                        }
                    }
                }
            }
        }
    }
    .resort-activities{
        .resort-activities-img{
            position: absolute;
            top: -10%;
            width: 60vw;
        }
    }
    .center-slider{
        .slick-list{
            .slick-slide{
                .slide-inner{
                    img{
                        border: 2px solid transparent;
                    }
                }
            }
        }
    }
    .center-slider-back{
        bottom: 8%;
    }
}
@media (max-width: 767px) {
    .holiday-para-box-back{
        .resort-single-name{
            margin-bottom: 10px;
        }
        .holiday-para-box{
            padding: 25px 8.33333%;
        }
        .holiday-contact{
            padding: 25px 8.3333%;
        }
    }
}
@media (max-width: 575px) {
    .holiday-para-box-back{
        margin-bottom: 0;
    }
    .resort-activities{
        .resort-activities-img{
            width: 80vw;
        }
    }
}
