.btn {
    &-primary {
        background-color: $darkBlue;
        border-color: $darkBlue;
        outline: none !important;
        box-shadow: none !important;
        border-radius: 0;
        text-transform: uppercase;
        font-size: 12px;
    }
}

.sec-title {
    font-size: 50px;
    font-weight: 600;
    letter-spacing: 5px;
    padding-right: 15px;
}

.object {
    &-fit {
        &-contain {
            object-fit: contain !important;
        }
        &-cover {
            object-fit: cover !important;
        }
    }
    &-position {
        &-c {
            object-position: center;
        }
        &-cl {
            object-position: center left;
        }
        &-cr {
            object-position: center right;
        }
        &-tc {
            object-position: top center;
        }
        &-tl {
            object-position: top left;
        }
        &-tr {
            object-position: top right;
        }
        &-bc {
            object-position: bottom center;
        }
        &-bl {
            object-position: bottom left;
        }
        &-br {
            object-position: bottom right;
        }
    }
}

.flip-h {
    transform: scaleX(-1);
}
@media (max-width: 1024px) {
    .sec-title {
        font-size: 40px;
    }
}
@media (max-width: 992px) {
    .sec-title {
        font-size: 30px;
    }
}
@media (max-width: 576px) {
    .sec-title {
        padding: 25px;
    }
}
