.about-para-back{
    margin: 20px 0 50px;
}
.about-para{
    line-height: 35px;
    font-weight: 500;
    color: $lightBrown;
    font-size: 15px;
}
.btn-rates-sm{
    width: 160px;
}
.about-bg-img{
    position: absolute;
    width: 30%;
}
@media(max-width: 1024px){
    .about-bg-img{
        width: 50%;
    }
}
@media(max-width: 768px){
    .about-bg-img{
        width: 60%;
    }
}
@media(max-width: 576px){
    .about-bg-img{
        width: 80%;
    }
}
