$font-family: "Montserrat";
$font-weights: (
    (
        text: "Thin",
        value: 100
    ),
    (
        text: "ExtraLight",
        value: 200
    ),
    (
        text: "Light",
        value: 300
    ),
    (
        text: "Regular",
        value: 400
    ),
    (
        text: "Medium",
        value: 500
    ),
    (
        text: "SemiBold",
        value: 600
    ),
    (
        text: "Bold",
        value: 700
    ),
    (
        text: "ExtraBold",
        value: 800
    ),
    (
        text: "Black",
        value: 900
    )
);
@each $item in $font-weights {
    @font-face {
        font-family: $font-family;
        src: url("../../fonts/#{$font-family}-#{map-get($item, text)}.ttf");
        font-weight: map-get($item, value);
    }
}

html,
body {
    font-family: $font-family, sans-serif;
    font-weight: 400;
}

.text-yellow {
    color: $yellow !important;
}
