.reservations-box {
    margin-bottom: 70px;
    position: relative;
    .resort-bg-img {
        right: 0;
        top: 0;
    }
    .image-sec {
        background-color: $lightGray;
        position: relative;
        img {
            object-fit: contain;
        }
        .text {
            position: absolute;
            top: 0;
            left: 0;
            color: $darkGray;
            justify-content: flex-end;
            h2 {
                font-size: 27px;
                letter-spacing: 2px;
                font-weight: 600;
                position: relative;
                text-transform: uppercase;
                margin-bottom: 17px;
            }
            p {
                font-weight: 500;
                font-size: 14px;
                margin: 0;
                padding-right: 60px;
                line-height: 25px;
            }
        }
    }
    .text-box-sec {
        .contact-box-mar {
            margin: 15px -10px 0 !important;
            justify-content: center;
            .contact-box-pad {
                flex: 0 0 33.333%;
                max-width: 33.333%;
                padding: 0 10px 20px;
                @include media-breakpoint-down(lg) {
                    flex: 0 0 33.333%;
                    max-width: 33.333%;
                }
                @include media-breakpoint-down(md) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
                @include media-breakpoint-down(xs) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                .box-text {
                    padding: 20px 10px;
                    background: #fff;
                    border: 9px solid #00000008;
                    height: 100%;
                    @include media-breakpoint-down(lg) {
                        background: #fff;
                    }
                    .title {
                        font-size: 23px;
                        font-weight: 700;
                        color: $darkGray;
                        margin: 0 auto 25px;
                        width: fit-content;
                        position: relative;
                        span {
                            position: absolute;
                            top: 0;
                            left: -6px;
                            height: 100%;
                            width: 2px;
                            background-color: $yellow;
                        }
                        @include media-breakpoint-down(lg) {
                            font-size: 20px;
                        }
                    }
                    .resort-text {
                        font-size: 18px;
                        font-weight: 600;
                        color: $darkGray;
                        opacity: 0.8;
                        width: 100%;
                        text-align: center;
                        @include media-breakpoint-down(lg) {
                            font-size: 16px;
                        }
                    }
                    .resort-num {
                        font-size: 14px;
                        font-weight: 500;
                        color: $darkGray;
                        opacity: 0.7;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
        .box-mar {
            margin-left: -50px;
            .box-pad {
                padding: 30px;
                .box-text {
                    padding: 30px;
                    background: #fff;
                    border: 9px solid #00000008;
                    height: 100%;
                    h1 {
                        font-size: 20px;
                        font-weight: 600;
                        border-left: 2px solid $yellow;
                        color: $darkGray;
                        padding-left: 5px;
                        margin-bottom: 25px;
                    }
                    h2 {
                        font-size: 14px;
                        font-weight: 600;
                        margin-top: -10px;
                        margin-bottom: 20px;
                    }
                    p {
                        display: flex;
                        font-size: 14px;
                        font-weight: 500;
                        color: $darkGray;
                        margin-bottom: 12px;
                        span {
                            display: block;
                            width: 50%;
                            img {
                                width: 13px;
                                margin-right: 10px;
                            }
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
.reservation-form-bg {
    top: 20%;
    left: 0;
    position: absolute;
    width: 20%;
}
.reservation-form {
    margin-bottom: 40px;
    padding: 30px !important;
    background: #fff;
    border: 9px solid #00000008;
    input,
    select {
        color: #243238;
        font-size: 14px;
        font-weight: 500;
        background-color: rgba(0, 0, 0, 0.03137);
        border: 1px solid #24323854;
        border-radius: 0;
        height: 40px;
    }
    textarea {
        color: #243238;
        font-size: 14px;
        font-weight: 500;
        background-color: rgba(0, 0, 0, 0.03137);
        border: 1px solid #24323854;
        border-radius: 0;
    }
    .form-control:focus {
        color: #495057;
        background-color: #fff;
        border-color: #ffcb08;
        outline: 0;
        box-shadow: 0 0 0 0.2rem #ffcb0833;
    }
    label {
        color: #243238;
        font-size: 14px;
        font-weight: 500;
    }
    .btn-primary {
        min-width: 150px;
        margin-top: 20px;
    }
}

.video-sec-res {
    margin-bottom: 40px;
    padding: 0 30px !important;
    background: #fff;
}

.reservation-table {
    margin: 20px 0 70px;
    table {
        margin-bottom: 0;
        color: #243238;
        font-size: 13px;
        font-weight: 500;
    }
    .table-bordered th,
    .table-bordered td {
        border: 1px solid #243238;
        vertical-align: middle;
    }
}

@media (max-width: 1024px) {
    .reservations-box {
        .image-sec {
            .text {
                h2 {
                    font-size: 25px;
                }
            }
        }
        .text-box-sec {
            .box-mar {
                margin-left: -40px;
                .box-pad {
                    padding: 20px !important;
                    .box-text {
                        padding: 20px;
                    }
                }
            }
        }
    }
}
@media (max-width: 991px) {
    .reservations-box {
        .image-sec {
            .text {
                h2 {
                    font-size: 20px;
                }
                p {
                    padding-right: 40px;
                }
            }
        }
        .text-box-sec {
            .box-mar {
                margin-left: -50px;
                .box-pad {
                    padding: 15px !important;
                    .box-text {
                        h1 {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .reservation-form {
        padding: 20px !important;
    }
}
@media (max-width: 767px) {
    .reservations-box {
        .resort-bg-img {
            top: unset;
            bottom: 0;
        }
        .image-sec {
            .text {
                h2 {
                    margin-bottom: 10px;
                }
            }
        }
        .text-box-sec {
            .box-mar {
                margin-left: 0;
                margin-top: -15%;
            }
        }
    }
    .reservation-table {
        margin: 0 0 70px;
    }
    .reservation-form {
        padding: 10px !important;
    }
    .video-sec-res {
        iframe {
            transform: scale(0.6);
        }
    }
}
.reservation-modal {
    .modal-header {
        border: none;
    }
    .modal-body {
        padding: 0 15px 15px;
        h1 {
            font-size: 20px;
            text-align: center;
        }
        p {
            color: #ffcb08;
            font-weight: 700;
            text-align: center;
            font-size: 25px;
        }
    }
}
.room-type-btn {
    padding: 2px;
    font-size: 12px;
}
.total-amount-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 15px 0;
    border-bottom: 1px solid #2432381c;
    .text-topic {
        min-width: 250px;
        margin-bottom: 5px;
        font-size: 18px;
    }
    .text {
        color: #ffcb08;
        margin-bottom: 5px;
    }
}

.selected-rooms {
}
.contact-heading {
    font-size: 30px;
    font-weight: 700;
    color: $darkGray;
    width: fit-content;
    margin: 0 auto 25px;
    position: relative;
    span {
        position: absolute;
        top: 0;
        left: -10px;
        height: 100%;
        width: 2px;
        background-color: $yellow;
    }
    @include media-breakpoint-down(md) {
        font-size: 28px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 23px;
    }
}
.rates-btn-wrap {
    .rates-btn {
        background-color: $yellow;
        text-transform: uppercase;
        color: $darkGray;
        font-size: 16px;
        border-radius: 10px;
        box-shadow: 2px 4px 4px #00000026;
        padding: 10px;
        font-weight: 500;
        border: 1px solid transparent;
        transition: 0.5s;
        margin: 0 auto 25px;
        min-width: 180px;
        display: block;
        &:hover {
            border: 1px solid $yellow;
            background-color: $white;
        }
        @include media-breakpoint-down(sm) {
            font-size: 14px;
            border-radius: 8px;
            box-shadow: 2px 4px 4px #00000026;
            padding: 8px;
            min-width: 150px;
        }
    }
}
.contact-office {
    margin-bottom: 70px;
    h2 {
        font-size: 18px;
        font-weight: 600;
        margin-top: -10px;
        margin-bottom: 20px;
        text-align: center;
    }
    &-text {
        display: flex;
        flex-wrap: wrap;
        font-size: 15px;
        font-weight: 500;
        color: $darkGray;
        margin-bottom: 10px;
        justify-content: center;
        width: 100%;
        a {
            color: $darkGray;
        }
        span {
            img {
                width: 13px;
                margin-right: 10px;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.contact-modal {
    &-header {
        position: relative;
        padding: 40px 0;
    }
    &-close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        .close-icon {
            width: 15px;
        }
    }
    &.table {
        border-collapse: separate;
        border-spacing: 6px;
        text-align: center;
        color: $darkGray;
        thead {
            background-color: $yellow;
            font-size: 15px;
            th {
                border: none;
                font-weight: 600;
                &:first-child {
                    border-top-left-radius: 5px;
                }
                &:last-child {
                    border-top-right-radius: 5px;
                }
            }
        }
        tbody {
            font-size: 14px;
            td {
                font-weight: 500;
                border: 1px solid #00000024;
                border-radius: 5px;
            }
        }
    }
}
